import './Controls.css';
import {useState} from 'react';
import locationIcon from './list-history.svg';
import liveUpdateIcon from './play-button.svg';
import doctorPng from './doctor.png';
import MedicalDialog from './MedicalDialog';
import LocationDialog from './LocationDialog';
import AvatarDialog from './AvatarDialog';

const Controls = ({
  user,
  locations = [],
  setLocation,
  refreshInterval,
  startUpdating,
  stopUpdating,
}) => {
  const [medicalOpen, setMedicalOpen] = useState(false);
  const handleCloseMedical = () => {
    setMedicalOpen(false);
  };
  const [locationOpen, setLocationOpen] = useState(false);
  const handleCloseLocation = () => {
    setLocationOpen(false);
  };
  const handleSelectLocation = (location) => {
    setLocation(location);
    setLocationOpen(false);
    stopUpdating();
  };
  const [avatarOpen, setAvatarOpen] = useState(false);
  const handleCloseAvatar = () => {
    setAvatarOpen(false);
  };
  return (
    <div id="controls">
      <div className="left-panel">
        <div className="control-button" onClick={() => setMedicalOpen(true)}>
          <img src={doctorPng} alt="medical-info" />
        </div>
        {refreshInterval ? (
          <div className="control-button" onClick={() => setLocationOpen(true)}>
            <img src={locationIcon} alt="location-icon" />
          </div>
        ) : (
          <div className="control-button" onClick={startUpdating}>
            <img src={liveUpdateIcon} alt="live-update" />
          </div>
        )}
      </div>
      <div id="user-photo-wrap" onClick={() => setAvatarOpen(true)}>
        <img className="user-photo" src={user.avatar} alt="" />
      </div>
      {medicalOpen && (
        <MedicalDialog open={medicalOpen} handleClose={handleCloseMedical} />
      )}
      <LocationDialog
        open={locationOpen}
        handleClose={handleCloseLocation}
        locations={locations}
        handleSelectLocation={handleSelectLocation}
      />
      <AvatarDialog
        open={avatarOpen}
        handleClose={handleCloseAvatar}
        avatar={user.avatar}
      />
    </div>
  );
};

export default Controls;
