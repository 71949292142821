import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {convertTimeStampToDay, fetchMedical} from './utils';

const MedicalDialog = ({open, handleClose}) => {
  const params = useParams();
  const uuid = params.uuid;
  const [loading, setLoading] = useState(true);
  const [medical, setMedical] = useState({});
  const [expired, setExpired] = useState(false);
  const getMedical = async () => {
    try {
      const medicalData = await fetchMedical(uuid);
      if (typeof medicalData === 'object') {
        setMedical(medicalData);
      } else {
        setExpired(true);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    getMedical();
  }, []);

  return (
    <Dialog maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle>Medical Info</DialogTitle>
      <DialogContent dividers={true}>
        {loading ? (
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress />
          </Box>
        ) : expired ? (
          <p>Access to medical info expired.</p>
        ) : (
          <MedicalTable medical={medical} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const MedicalTable = ({medical}) => (
  <>
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 650}} size="small">
        <TableBody>
          <TableRow>
            <TableCell sx={{width: '50%'}}>
              Latest Medical Info Update
            </TableCell>
            <TableCell sx={{width: '50%'}}>
              {convertTimeStampToDay(medical?.update_time)}
            </TableCell>
          </TableRow>
          <TableRow classes={{root: 'gray-background'}}>
            <TableCell>Medications</TableCell>
            <TableCell>{medical?.medications}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Conditions</TableCell>
            <TableCell>{medical?.conditions}</TableCell>
          </TableRow>
          <TableRow classes={{root: 'gray-background'}}>
            <TableCell>Allergies</TableCell>
            <TableCell>{medical?.allergies}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Personal Medical Devices</TableCell>
            <TableCell>{medical?.personal_medical_devices}</TableCell>
          </TableRow>
          <TableRow classes={{root: 'gray-background'}}>
            <TableCell>Other info</TableCell>
            <TableCell>{medical?.additional_info}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <h4>Doctor</h4>
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 650}} size="small">
        <TableBody>
          <TableRow>
            <TableCell sx={{width: '50%'}}>Doctor</TableCell>
            <TableCell sx={{width: '50%'}}>{medical?.doctor}</TableCell>
          </TableRow>
          <TableRow classes={{root: 'gray-background'}}>
            <TableCell>Specialty</TableCell>
            <TableCell>{medical?.specialty}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Phone number 1</TableCell>
            <TableCell>{medical?.phone_first}</TableCell>
          </TableRow>
          <TableRow classes={{root: 'gray-background'}}>
            <TableCell>Phone Number 2</TableCell>
            <TableCell>{medical?.phone_second}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

export default MedicalDialog;
