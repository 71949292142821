import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
} from '@mui/material';
import {convertTimeStamp, convertAge, convertAccuracy} from './utils';

const LocationDialog = ({
  open,
  handleClose,
  locations,
  handleSelectLocation,
}) => {
  return (
    <Dialog maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle>Location history</DialogTitle>
      <DialogContent dividers={true}>
        <p>showing {locations.length} items.</p>
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 650}} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Time</TableCell>
                <TableCell>Coordinates</TableCell>
                <TableCell>Accuracy</TableCell>
                <TableCell>Age</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locations.map((location, i) => {
                const className = i % 2 === 0 ? 'gray-background' : '';
                return (
                  <TableRow
                    key={location.user_timestamp}
                    classes={{root: className}}
                  >
                    <TableCell onClick={() => handleSelectLocation(location)}>
                      <Link underline="hover">
                        {convertTimeStamp(location.user_timestamp)}
                      </Link>
                    </TableCell>
                    <TableCell onClick={() => handleSelectLocation(location)}>
                      <Link underline="hover">
                        {location.latitude}, {location.longitude}
                      </Link>
                    </TableCell>
                    <TableCell>{convertAccuracy(location.accuracy)}</TableCell>
                    <TableCell>{convertAge(location.age)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocationDialog;
