import {Wrapper, Status} from '@googlemaps/react-wrapper';
import React, {useEffect, useRef, useState} from 'react';
import './Map.css';

const API_KEY = process.env.REACT_APP_GMAP_API_KEY;

const ZOOM = 16;

const Map = ({latitude, longitude, user_timestamp, accuracy, age}) => {
  const center = {lat: +latitude, lng: +longitude};
  return (
    <Wrapper apiKey={API_KEY} render={render}>
      <MyMapComponent center={center} />
    </Wrapper>
  );
};

const render = (status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

const MyMapComponent = ({center}) => {
  const ref = useRef();
  const [map, setMap] = useState();
  const [marker, setMarker] = useState();
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const preCenter = usePrevious(center);
  useEffect(() => {
    const initMap = new window.google.maps.Map(ref.current, {
      center: {lat: 45, lng: -70},
      zoom: ZOOM,
    });
    setMap(initMap);
  }, []);
  useEffect(() => {
    if (
      map &&
      center.lat &&
      center.lng &&
      (preCenter.lat !== center.lat || preCenter.lng !== center.lng || !marker)
    ) {
      map.setCenter(center);
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({location: center}, (results, status) => {
        let address = 'LOADING...';

        if (status === 'OK' && results[0]) {
          address = results[0].formatted_address;
        } else {
          console.error('Geocoder failed due to: ' + status);
        }
        if (marker) {
          marker.setPosition(center);
          marker.setLabel({
            text: address,
            className: 'address-label',
            fontSize: '2em',
          });
        } else {
          const newMarker = new window.google.maps.Marker({
            position: center,
            map,
            label: {
              text: address,
              className: 'address-label',
              fontSize: '2em',
            },
          });
          setMarker(newMarker);
        }
      });
    }
  }, [map, center, marker, preCenter]);

  return <div ref={ref} id="map" />;
};

export default Map;
