import './Error.css';

const Error = ({message}) => {
  return (
    <div className="container">
      <p>{message || 'Incorrect location uuid'}</p>
    </div>
  );
};

export default Error;
