import {format, isValid, fromUnixTime} from 'date-fns';

const API_BASE = process.env.REACT_APP_API_BASE;

export const convertTimeStamp = (user_timestamp) =>
  isValid(user_timestamp)
    ? format(fromUnixTime(user_timestamp), 'hh:mm:ss a')
    : '';

export const convertTimeStampToDay = (timestamp) =>
  isValid(timestamp) ? format(fromUnixTime(timestamp), 'yyyy-MM-dd') : '';

export const convertAge = (age) => {
  if (age < 0) {
    return '0s';
  }
  if (age <= 60) {
    return `${age}s`;
  }
  const seconds = age % 60;
  const minutes = (age - seconds) / 60;
  return `${minutes}m ${seconds}s`;
};

export const convertAccuracy = (accuracy) => {
  if (accuracy < 0) {
    return '0m';
  }
  if (accuracy <= 1000) {
    return `${accuracy}m`;
  }
  const remain = accuracy % 1000;
  const kilo = (accuracy - remain) / 1000;
  return `${kilo}km ${remain}m`;
};

export const fetchUser = async (uuid) =>
  fetch(`${API_BASE}/user`, {
    method: 'POST',
    body: JSON.stringify({uuid}),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text();
      }
    })
    .catch((e) => {
      console.error(e);
    });

export const fetchLocations = async (uuid) =>
  fetch(`${API_BASE}/locations`, {
    method: 'POST',
    body: JSON.stringify({uuid}),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text();
      }
    })
    .catch((e) => {
      console.error(e);
    });

export const fetchMedical = async (uuid) =>
  fetch(`${API_BASE}/medical`, {
    method: 'POST',
    body: JSON.stringify({uuid}),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text();
      }
    })
    .catch((e) => {
      console.error(e);
    });
