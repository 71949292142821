import './App.css';
import {Routes, Route} from 'react-router-dom';
import Main from './Main';
import Error from './Error';

function App() {
  return (
    <Routes>
      <Route path="/:uuid" element={<Main />} />
      <Route path="/" element={<Error />} />
    </Routes>
  );
}

export default App;
