import React from 'react';
import './FloatingPanel.css';
import {convertTimeStamp, convertAge, convertAccuracy} from './utils';

const FloatingPanel = ({age = 0, accuracy = 0, user_timestamp}) => {
  const timeText = convertTimeStamp(user_timestamp);
  const ageText = convertAge(age);
  const accuracyText = convertAccuracy(accuracy);
  return (
    <div id="floating-panel">
      <div id="note">
        Location captured: {timeText}, {ageText} old, Accuracy is:{' '}
        {accuracyText}
      </div>
    </div>
  );
};

export default FloatingPanel;
