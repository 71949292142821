import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import './Main.css';
import FloatingPanel from './FloatingPanel';
import Map from './Map';
import Controls from './Controls';
import Error from './Error';
import {fetchUser, fetchLocations} from './utils';

const AVATAR_HOST = process.env.REACT_APP_AVATAR_HOST;

const Main = () => {
  const params = useParams();
  const uuid = params.uuid;
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState({});
  const [refreshInterval, setRefreshInterval] = useState();
  const [user, setUser] = useState({});
  const [error, setError] = useState();
  // get location
  const getLocations = async () => {
    try {
      const locations = await fetchLocations(uuid);
      if (typeof locations === 'object') {
        setLocations(locations);
        setLocation(locations[0]);
      } else {
        setError(locations);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getUser = async () => {
    // get user
    try {
      const user = await fetchUser(uuid);
      if (typeof user === 'object') {
        setUser({
          avatar: `${AVATAR_HOST}/${user?.avatar}`,
        });
      } else {
        setError(user);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const startUpdating = () => {
    if (refreshInterval) {
      return;
    }
    getLocations();
    const interval = setInterval(() => {
      getLocations();
    }, 5000);
    setRefreshInterval(interval);
  };
  const stopUpdating = () => {
    clearInterval(refreshInterval);
    setRefreshInterval(null);
  };
  useEffect(() => {
    startUpdating();
    getUser();
    return stopUpdating;
  }, []);
  if (error) {
    return <Error message={error} />;
  }
  return (
    <div id="map-wrap">
      <FloatingPanel {...location} />
      <Map {...location} />
      <Controls
        user={user}
        locations={locations}
        setLocation={setLocation}
        refreshInterval={refreshInterval}
        startUpdating={startUpdating}
        stopUpdating={stopUpdating}
      />
    </div>
  );
};
export default Main;
