import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

const AvatarDialog = ({avatar, open, handleClose}) => {
  return (
    <Dialog fullWidth={true} open={open} onClose={handleClose}>
      <DialogTitle>User avatar</DialogTitle>
      <DialogContent dividers={true}>
        <div
          style={{
            backgroundImage: `url(${avatar})`,
            height: '70vh',
            width: '100%',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AvatarDialog;
